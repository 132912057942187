@use 'src/styles/config' as *;

.root {
  margin: 0 (-$content-spacing) -1rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    margin-left: -$content-spacing-mobile;
    margin-right: -$content-spacing-mobile;
  }
}
