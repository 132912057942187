@use 'src/styles/config' as *;

.root {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  position: relative;
  grid-gap: 1.7rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    grid-gap: 1rem;
  }
}
