@use 'src/styles/config' as *;

.root {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 1.2rem 1.6rem 1.2rem 0.6rem;
  position: sticky;
  bottom: 0;
  z-index: 1200;
  margin-bottom: -1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    padding: 1.2rem 1.6rem !important;
  }
}
