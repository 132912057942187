@use 'src/styles/config' as *;
.root {
  text-align: center;
  img {
    max-width: 100%;
  }
}
.image {
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.08);
}

.top {
  display: inline-block;
  padding: 0 6.5rem;
  position: relative;
}
.icon {
  position: absolute;
  width: 37%;
  bottom: -32%;
  right: 0;
  img {
    width: 100%;
  }
}
