@use 'src/styles/config' as *;

.root {
  height: 6rem;
  min-width: 0;
  position: sticky;
  top: 0;
  margin: 0 (-$content-spacing);
  padding: 0 $content-spacing;
  background-color: $color-white;
  z-index: 99;
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    grid-gap: 1.6rem;
    margin: 0 (-$content-spacing-mobile);
    padding-left: 1rem;
    padding-right: $content-spacing-mobile;
  }
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    padding-left: 1.6rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    margin-left: 0;
    margin-right: 0;
    padding-right: 1.6rem;
    padding-left: 1rem;
  }
}
