$box-shadow-dark-blue: 0 0.4rem 2.4rem 0 rgba(#0d334b, 0.1);
$button-shadow: 0 0.8rem 0.8rem 0 rgba(#000, 0.15);

$header-height: 6rem;
$header-height-mobile: 4.8rem;

$panel-width: 7rem;
$panel-width-open: 26rem;

$drawer-width-open: 25.6rem;
$drawer-width-close: 6.4rem;

$fontMain: 'Roboto', sans-serif;

$content-spacing: 2.8rem;
$content-spacing-mobile: 2.4rem;
