@use 'src/styles/config' as *;

.root {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.6rem 1.5rem;
  color: $color-text-primary;
  justify-content: inherit;
  text-align: left;
  grid-gap: 1rem;
  margin: 0 0.2rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0.75rem;
    right: 0.75rem;
    height: 1px;
    border-bottom: 1px solid $color-background;
    border-top: 1px solid darken($color-nav-bg, 5%);
  }
  &:after {
    top: auto;
    bottom: 0;
  }
  &:hover {
    background-color: $color-grey-button;
  }
  &Open {
    .name {
      opacity: 1;
      width: 12rem;
    }
    .arrow {
      margin-left: 0;
      font-size: 2rem;
    }
  }
}

.arrow {
  transition: 0.3s;
  margin-left: -1.7rem;
  font-size: 1.6rem;
}
.name {
  width: 0;
  opacity: 0;
  transition: 0.3s;
  flex: 1 1 100%;
}
@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
}
