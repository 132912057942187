:root {
  --size: 120;
  --coefficient: 1px;
  --timeline: 2.6s;
  --delay: 0.65s;
  --rotation-y: -24;
  --rotation-x: 28;
  --color-one: #2065d1;
  --color-two: #142570;
  --color-three: #4cc9f0;
}

.root {
  z-index: 100000;
  transform-origin: 50% 50%;
  animation: scale var(--timeline) var(--delay) infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  place-items: center;
  font-family: 'Google Sans', sans-serif, system-ui;

  *,
  *:after,
  *:before {
    box-sizing: border-box;
    transform-style: preserve-3d;
  }
}

.scene {
  position: relative;
  transform: translate3d(0, 0, 100vmin) rotateX(calc(var(--rotation-y, 0) * 1deg))
    rotateY(calc(var(--rotation-x, 0) * 1deg)) rotateX(0deg);
}

@keyframes scale {
  0%,
  10% {
    transform: scaleX(1) scaleY(1);
  }
  35%,
  100% {
    transform: scaleX(0.5) scaleY(0.5);
  }
}

.shadow {
  width: calc(var(--size) * var(--coefficient));
  position: absolute;
  bottom: 0;
  aspect-ratio: 1;
  transform-origin: 50% 50%;
  background: hsl(210 80% 50% / 0.2);
  transform: rotateX(90deg)
    translate3d(0, 0, calc((var(--size) * (var(--coefficient) * -0.5)) - 1px)) scale(0.96);
  animation:
    squish-squosh var(--timeline) var(--delay) infinite,
    fade var(--timeline) var(--delay) infinite;
}

.loader {
  --depth: var(--size);
  --color: var(--color-one, #8338ec);
  width: calc(var(--depth) * var(--coefficient));
  aspect-ratio: 1;
  transform-origin: 50% 50%;
  animation: squish-squosh var(--timeline) var(--delay) infinite;
}

.spinner {
  animation: spin var(--timeline) var(--delay) infinite;
}

.jumper {
  animation: jump var(--timeline) var(--delay) infinite;
}

@keyframes squish-squosh {
  0%,
  50%,
  60% {
    scale: 1 1 1;
  }
  10%,
  35% {
    scale: 1.2 0.8 1.2;
  }
  25% {
    scale: 0.8 1.2 0.8;
  }
  70% {
    scale: 1 1 2;
  }
  80% {
    scale: 2 1 2;
  }
  90%,
  100% {
    scale: 2 2 2;
  }
}

@keyframes fade {
  0%,
  10%,
  40%,
  50%,
  60%,
  100% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
}

@keyframes spin {
  0%,
  10% {
    rotate: 0deg;
  }
  30%,
  100% {
    rotate: -360deg;
  }
}
@keyframes jump {
  0%,
  10%,
  35%,
  50% {
    translate: 0 0;
  }
  25% {
    translate: 0 -150%;
  }
}

/* Cuboid boilerplate code */
.cuboid {
  width: 100%;
  height: 100%;
  position: relative;
}
.cuboid__side {
  background: var(--color);
  position: absolute;
}
.cuboid__side:nth-of-type(1) {
  --b: 1.1;
  height: calc(var(--depth, 20) * var(--coefficient));
  width: 100%;
  top: 0;
  transform: translate(0, -50%) rotateX(90deg);
}
.cuboid__side:nth-of-type(2) {
  --b: 0.9;
  --color: var(--color-three, #ff006e);
  height: 100%;
  width: calc(var(--depth, 20) * var(--coefficient));
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotateY(90deg);
}
.cuboid__side:nth-of-type(3) {
  --b: 1;
  width: 100%;
  height: calc(var(--depth, 20) * var(--coefficient));
  bottom: 0;
  transform: translate(0%, 50%) rotateX(90deg);
}
.cuboid__side:nth-of-type(4) {
  --b: 1;
  --color: var(--color-three, #ff006e);
  height: 100%;
  width: calc(var(--depth, 20) * var(--coefficient));
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotateY(90deg);
}
.cuboid__side:nth-of-type(5) {
  --b: 1;
  --color: var(--color-two, #3a86ef);
  height: 100%;
  width: 100%;
  transform: translate3d(0, 0, calc(var(--depth, 20) * (var(--coefficient) * 0.5)));
  top: 0;
  left: 0;
}
.cuboid__side:nth-of-type(6) {
  --b: 1.2;
  height: 100%;
  width: 100%;
  transform: translate3d(0, 0, calc(var(--depth, 20) * (var(--coefficient) * -0.5))) rotateY(180deg);
  top: 0;
  left: 0;
}
