@use 'src/styles/config' as *;

.tabs {
  grid-template-columns: 19rem 1fr;
  overflow: visible;
}
@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .tabs {
    grid-template-columns: 1fr;
  }
}
@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    grid-template-rows: auto 1fr auto;
  }
  .tabsWrap {
    padding-top: 0;
  }
}
