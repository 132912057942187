@use 'src/styles/config' as *;

.root {
  display: flex;
  min-height: 100%;
  gap: 1.6rem;
  //padding-bottom: 1.6rem;
}

.divider {
  width: 1px;
  background-color: $color-border;
  margin-top: -1.6rem;
  position: relative;
  z-index: 10;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    flex-direction: column;
  }
  .divider {
    height: 1px;
    width: auto;
    margin: 0 -1.6rem;
  }
}
